import React, { useEffect, useState } from "react";
import ShipmentForm from "./ShipmentForm";
import "./style/shipment-details.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../../constants";
import ShipmentPoes from "./ShipmentPoes";
import ShipmentDocuments from "./ShipmentDocuments";
import { formatDate } from "@telerik/kendo-intl";
import KendoLoadingPanel from "./KendoLoadingPanel";

const ShipmentDetails = () => {
  const { id } = useParams();
  const [shipment, setShipment] = useState();
  const loadData = async () => {
    const res = await axios.get(`${BASE_URL}/Job/${id}`);
    if (res.status === 200) {
      setShipment(res.data);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="shipment-details-page shipment-component">
      {!shipment && <KendoLoadingPanel />}
      <div className="voyage-background">
        <img src="voyage-banner.png"className="banner-image" loading="lazy" alt="voyage-bg" height={240} />
        <div className="banner-content">
          <div className="shipment-banner-details">
            <div className="detail-1">Shipment Details</div>
            <div>
              <hr className="detail-divider" />
            </div>
            <div className="detail-desc">{shipment?.jobNo}</div>
          </div>
          <div className="detail-2">MAWB/L : {shipment?.awb}</div>
          <div className="locationbar-container">
            <div>{shipment?.originPortCode}</div>
            <div>
              <img src="locationBar.svg" />
            </div>
            <div>{shipment?.destinationPortCode}</div>
          </div>
          <div className="sub-details">
            <div className="sub-details-1">
              <div className="sub-detail-name">Vessel</div>
              <div className="sub-detail-desc">{shipment?.vesselName}</div>
              <hr className="sub-divider divider-1" />
            </div>
            <div className="sub-details-1">
              <div className="sub-detail-name">ETD Origin</div>
              <div className="sub-detail-desc">
                {shipment?.etd &&
                  formatDate(new Date(shipment?.etd), "dd MMM yyyy")}
              </div>
              <hr className="sub-divider divider-2" />
            </div>
            <div className="sub-details-1">
              <div className="sub-detail-name">ETA Destination</div>
              <div className="sub-detail-desc">
                {shipment?.eta &&
                  formatDate(new Date(shipment?.eta), "dd MMM yyyy")}
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-details">
          <Tabs>
            <div className="tabs">
              <div className="tabs-header">
                <label className="label-1">Shipment /</label> Shipment Details
              </div>
              <TabList>
                <Tab>Details</Tab>
                <Tab>
                  POs(
                  {
                    shipment?.poes.length
                    // .map((poe) => poe?.packages?.length)
                    // .reduce((a, b) => a + b, 0)
                  }
                  s)
                </Tab>
                <Tab>Documents</Tab>
              </TabList>
            </div>
            <TabPanel>
              <ShipmentForm shipment={shipment} />
            </TabPanel>
            <TabPanel>
              <ShipmentPoes shipment={shipment} />
            </TabPanel>
            <TabPanel>
              <ShipmentDocuments shipments={shipment} />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default ShipmentDetails;
