import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { formatDate } from "@telerik/kendo-intl";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL, TransportModes } from "../../constants";
import "../../style/shipment.css";
import { process } from "@progress/kendo-data-query";
import KendoLoadingPanel from "./shipmentDetails/KendoLoadingPanel";
import { DateRangePicker } from "@progress/kendo-react-dateinputs";

const KendoDateCell = (props) => {
  const value = props.dataItem[props.field];
  return (
    <td className=" bg-inherit">
      {formatDate(new Date(value), "dd-MMM-yyyy")}
    </td>
  );
};
const Shipments = ({ mapShow }) => {
  const navigate = useNavigate();
  const { filterby, id } = useParams();
  const columns = [
    {
      field: "jobNo",
      width: "150",
      textAlign: "center",
      headerText: "JOB NO",
    },
    {
      headerText: "B/L OR AWB/L",
      width: "125",
      textAlign: "center",
      field: "awb",
    },
    {
      headerText: "SHIPPER",
      field: "shipperName",
      // width: "250",
      textAlign: "center",
    },
    {
      headerText: "DATE",
      field: "creationDate",
      width: "130",
      textAlign: "center",
      cell: KendoDateCell,
    },
    {
      headerText: "ORIGIN PORT",
      field: "originPortName",
      // width: "170",
      textAlign: "center",
    },
    {
      headerText: "DESTINATION PORT",
      field: "destinationPortName",
      // width: "200",
      textAlign: "center",
    },
    {
      headerText: "VESSEL",
      field: "vesselName",
      // width: "185",
      textAlign: "center",
    },
    {
      headerText: "ORIGIN",
      field: "origin",
      // width: "150",
      textAlign: "center",
    },
    {
      headerText: "DESTINATION",
      field: "destination",
      // width: "150",
      textAlign: "center",
    },
  ];

  const defaultPageState = {
    sort: [{ field: "creationDate", dir: "desc" }],
    skip: 0,
    take: 12,
  };

  const [shipments, setShipments] = useState([]);
  const [filterText, setFilterText] = useState();
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [pageState, setPageState] = useState(defaultPageState);
  const [loading, setLoading] = useState(false);

  const rowSelected = (e) => {
    navigate(`/shipmentDetails/${e.dataItem.id}`);
  };

  const LoadData = async (range) => {
    setLoading(true);
    setPageState(defaultPageState);
    const startDate = range.start.toISOString().slice(0, 23) + "Z";
    const endDate = range.end.toISOString().slice(0, 23) + "Z";
    const filterOptions =
      id !== "all"
        ? `&filterValue=${TransportModes.find((m) => m.name === id).id}`
        : "";
    const res = await axios.get(
      `${BASE_URL}/CustomerPortal/Jobs?filterType=${filterby}${filterOptions}&startRange=${startDate}&endRange=${endDate}`
    );
    const data = res.data;
    if (res.status === 200) {
      setShipments(data);
    }
    setLoading(false);
  };
  const onDataFilter = async () => {
    let text = "~ALL";
    if (filterText !== "" || filterText !== undefined) text = filterText;
    const res = await axios.get(
      `${BASE_URL}/CustomerPortal/JobsByFilter?vessel=${text}&awb=${text}`
    );
    const data = res.data.map((d) => ({
      ...d,
      originDestin: d.origin + "/" + d.destination,
    }));
    setShipments(data);
  };

  const onPageChange = (e) => {
    const state = { ...pageState, skip: e.page.skip, take: e.page.take };
    setPageState(state);
    //loadData(state);
  };

  const handleDateChange = (e) => {
    setDateRange(e.value);
  };

  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const startDate = new Date(currentYear, 0, 1);
    const range = { start: startDate, end: currentDate };
    setDateRange(range);
    LoadData(range);
  }, []);

  return (
    <div className="shipment-main">
      {mapShow && (
        <div className="map-container">
          <img src="map.svg" className="map" alt="map" />
          <div className="pointer1">
            <img src="shipPointer.svg" alt="pointer" />
            <img src="pointer.svg" alt="pointer" />
          </div>
          <div className="pointer2">
            <img src="shipPointer.svg" alt="pointer" />
            <img src="pointer.svg" alt="pointer" />
          </div>
          <div className="pointer3">
            <img src="shipPointer.svg" alt="pointer" />
            <img src="pointer.svg" alt="pointer" />
          </div>
          <div className="pointer4">
            <img src="shipPointer.svg" alt="pointer" />
            <img src="pointer.svg" alt="pointer" />
          </div>
          <div className="pointer5">
            <img src="shipPointer.svg" alt="pointer" />
            <img src="pointer.svg" alt="pointer" />
          </div>
        </div>
      )}
      <div className="list-container">
        <div className="header">
          <div className="header-details">
            <div className="header-name">
              <div className="header-name1">Shipments</div>
              {/* <div className="header-name2">Some Label</div> */}
            </div>
            <div className="list-count">{shipments.length}</div>
          </div>
          <div className="header-actions">
            <div className="dashboard-actions">
              <div className="report-date">
                <label className="report-label">Report of </label>
              </div>
              <div className="action-buttons">
                <div className="date-range-picker">
                  <DateRangePicker
                    style={{ height: "100%" }}
                    value={dateRange}
                    onChange={handleDateChange}
                  />
                </div>
                <button
                  type="button"
                  className="action-filter"
                  onClick={() => LoadData(dateRange)}
                  disabled={dateRange.end === null || dateRange.end === null}
                >
                  <img src="filterIcon.svg" />
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="grid">
          {loading && <KendoLoadingPanel />}
          <Grid
            data={process(shipments, pageState)}
            pageable={true}
            // filterable={config.filterable}
            // sortable={config.sortable}
            className=""
            style={{
              borderRadius: "10px",
              borderColor: "#E5E7EB",
              fontSize: "12px",
              fontFamily: "poppins",
              zIndex: 0,
              height: "77.5vh",
            }}
            onPageChange={onPageChange}
            // filter={pageState.filter}
            // onFilterChange={onGridFilter}
            // onDataStateChange={handleGridDataStateChange}
            onRowDoubleClick={rowSelected}
            // total={pageState.total}
            {...pageState}
            //{...gridState}
          >
            {columns.map((column) => {
              return (
                // !column.hidden && (
                <GridColumn
                  key={column.field}
                  field={column.field}
                  title={column.headerText}
                  width={column.width}
                  filterable={column.filterable ? true : false}
                  sortable={column.sortable ? true : false}
                  cell={column.cell}
                />
              );
              // );
            })}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Shipments;
