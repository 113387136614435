import React from "react";

export const TextLoader = () => {
  return (
    <div className="textloader-container">
      <div class="loader"></div>
      <div class="loader"></div>
    </div>
  );
};
