import React, { useState, useEffect, useContext } from "react";
import "../../style/dashboard.css";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/AppContext";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
  ChartTooltip,
  SeriesClickEvent,
} from "@progress/kendo-react-charts";
import "hammerjs";
import { TextLoader } from "./TextLoader";
import ListLoader from "./ListLoader";
import GraphLoader from "./GraphLoader";
import { DateRangePicker } from "@progress/kendo-react-dateinputs";

const ContentLoader = ({ field, data, width, height, marginTop }) => {
  return (
    <>
      {data ? (
        data[field]
      ) : (
        <div style={{ width: width, height: height, marginTop: marginTop }}>
          <TextLoader />
        </div>
      )}
    </>
  );
};
const Dashboard = () => {
  const authCtx = useContext(AppContext);
  const navigate = useNavigate();
  const [dashboardDetails, setDashboardDetails] = useState();
  const [dateRange, setDateRange] = useState({ start: null, end: null });

  function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const loadeData = async (range) => {
    setDashboardDetails(null);
    const startDate = range.start.toISOString().slice(0, 23) + "Z";
    const endDate = range.end.toISOString().slice(0, 23) + "Z";
    const res = await axios.get(
      `${BASE_URL}/CustomerPortal/DashboardDetails?startRange=${startDate}&endRange=${endDate}`
    );
    if (res.status === 200)
      setDashboardDetails({
        ...res.data,
        vesselPoCount: res.data.vesselPoCount.map((vpc) => ({
          ...vpc,
          color: getRandomColor(),
        })),
        locationPoCount: res.data.locationPoCount.map((wpc) => ({
          ...wpc,
          color: getRandomColor(),
          // cityCode: wpc.propertyName.replace(/[aeiou]/gi, ""),
        })),
      });
  };

  const handleDateChange = (e) => {
    setDateRange(e.value);
  };

  const handleBarClick = (e) => {
    navigate(`/stock/${e.dataItem.propertyName}`);
  };

  useEffect(() => {
    const currentDate = new Date();
    let startDate = new Date(currentDate);
    startDate.setFullYear(currentDate.getFullYear() - 1);
    console.log(startDate);
    const range = { start: startDate, end: currentDate };
    setDateRange(range);
    loadeData(range);
  }, []);

  const renderTooltip = ({ point }) => {
    console.log(point);
    return <span>{point.category}</span>;
  };

  return (
    <div className="dashboard-main">
      <div className="dashboard-header">
        <div className="dashboard-greeting">
          Hello , {authCtx.profile.displayname}{" "}
        </div>
        <div className="dashboard-actions">
          <div className="report-date">
            <label className="report-label">Report of </label>
          </div>
          <div className="action-buttons">
            <div className="date-range-picker">
              <DateRangePicker
                style={{ height: "100%" }}
                value={dateRange}
                onChange={handleDateChange}
              />
            </div>
            <button
              type="button"
              className="action-filter"
              onClick={() => loadeData(dateRange)}
              disabled={dateRange.end === null || dateRange.end === null}
            >
              <img src="filterIcon.svg" />
              Filter
            </button>
          </div>
        </div>
      </div>
      <div className="dashboard-details-screen">
        <div className="po-counts">
          <div className="po-counts-header">
            <div>Total Purchase Orders</div>
            <div className="moreIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
                height="20px"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                />
              </svg>
            </div>
          </div>
          <div
            className="po-total-count"
            onClick={() => navigate("/pos/all/all")}
          >
            <ContentLoader
              field="poCount"
              data={dashboardDetails}
              height="16px"
              width="60px"
              marginTop="40px"
            />
          </div>
          <div className="po-detail-count">
            <div
              className="count-div"
              onClick={() => navigate("/pos/status/InTransit")}
            >
              <div>In Transit</div>
              <div className="detail-count-value">
                <ContentLoader
                  data={dashboardDetails}
                  field="poInTransit"
                  width="44px"
                  height="12px"
                  marginTop="16px"
                />
              </div>
            </div>
            <div
              className="count-div"
              onClick={() => navigate("/pos/status/InWarehouse")}
            >
              <div>In Stock</div>
              <div className="detail-count-value">
                <ContentLoader
                  data={dashboardDetails}
                  field="poInStock"
                  width="44px"
                  height="12px"
                  marginTop="16px"
                />
              </div>
            </div>
            <div
              className="count-div"
              onClick={() => navigate("/pos/status/Delivered")}
            >
              <div>Delivered</div>
              <div className="detail-count-value">
                <ContentLoader
                  data={dashboardDetails}
                  field="poDelivered"
                  width="44px"
                  height="12px"
                  marginTop="16px"
                />
              </div>
            </div>
          </div>
          <div className="po-count-img">
            <img src="login-img.svg" height="248px" alt="login-img" />
          </div>
        </div>
        <div className="po-details">
          <div className="po-detail-list-head">
            <div>
              PO Details
              <span>{dashboardDetails?.poCount}</span>
            </div>
            <div className="moreIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="black"
                class="w-6 h-6"
                height="20px"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                />
              </svg>
            </div>
          </div>
          {dashboardDetails ? (
            <div className="po-details-list">
              {dashboardDetails.vesselPoCount?.map((po) => (
                <div
                  className="po-detail-list-item"
                  style={{ borderLeft: "4px solid " + po.color }}
                  key={po.propertyId}
                  onClick={() => navigate(`/pos/vessel/${po.propertyId}`)}
                >
                  <div className="shipName">{po.propertyName}</div> <hr />
                  <div className="pos">{po.poCount}</div>
                </div>
              ))}
            </div>
          ) : (
            <ListLoader />
          )}
        </div>
        <div className="dashboard-statitics">
          <div className="statitics-job-count">
            <div className="first-div">
              <div className="first-text">Total Jobs</div>
            </div>
            <div
              className="second-div"
              onClick={() => navigate("/shipments/all/all")}
            >
              <ContentLoader
                data={dashboardDetails}
                field="jobCount"
                width="70px"
                height="25px"
                marginTop="26px"
              />
            </div>
          </div>
          <div className="statitics-mode">
            <div
              className="mode-statitics-div"
              onClick={() => navigate("/shipments/mot/Sea")}
            >
              <label className="label">Sea </label>
              <label className="count">
                <ContentLoader
                  data={dashboardDetails}
                  field="jobSeaMode"
                  width="44px"
                  height="12px"
                  marginTop="16px"
                />
              </label>
            </div>
            <div
              className="mode-statitics-div"
              onClick={() => navigate("/shipments/mot/Air")}
            >
              <label className="label">Air </label>
              <label className="count">
                <ContentLoader
                  data={dashboardDetails}
                  field="jobAirMode"
                  width="44px"
                  height="12px"
                  marginTop="16px"
                />
              </label>
            </div>
            <div
              className="mode-statitics-div"
              onClick={() => navigate("/shipments/mot/Road")}
            >
              <label className="label">Road </label>
              <label className="count">
                <ContentLoader
                  data={dashboardDetails}
                  field="jobRoadMode"
                  width="44px"
                  height="12px"
                  marginTop="16px"
                />
              </label>
            </div>
            <div
              className="mode-statitics-div"
              onClick={() => navigate("/shipments/mot/Courier")}
            >
              <label className="label">Courier </label>
              <label className="count">
                <ContentLoader
                  data={dashboardDetails}
                  field="jobCourierMode"
                  width="44px"
                  height="12px"
                  marginTop="16px"
                />
              </label>
            </div>
            <div
              className="mode-statitics-div"
              onClick={() => navigate("/shipments/mot/Others")}
            >
              <label className="label">Others</label>
              <label className="count">
                <ContentLoader
                  data={dashboardDetails}
                  field="jobOthersMode"
                  width="44px"
                  height="12px"
                  marginTop="16px"
                />
              </label>
            </div>
          </div>
          <div className="statitics-graph">
            <div className="header">
              <div className="header-text">Stock by Warehouse</div>
              <div className="moreIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="black"
                  class="w-6 h-6"
                  height="20px"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                  />
                </svg>
              </div>
            </div>
            <div className="dashboard-chart">
              {dashboardDetails ? (
                <Chart onSeriesClick={handleBarClick}>
                  <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                      maxDivisions={10}
                      labels={{
                        visible: true,
                        font: "bold 8px Poppins",
                        margin: "4",
                        content: (l) => {
                          var text = l.text;

                          if (text.length > 8) {
                            text = text.substring(0, 8) + "...";
                          }
                          return text;
                        },
                      }}
                    />
                  </ChartCategoryAxis>
                  <ChartLegend
                    width={100}
                    position="bottom"
                    orientation="horizontal"
                  />

                  <ChartTooltip render={renderTooltip} />
                  <ChartSeries>
                    <ChartSeriesItem
                      type="column"
                      data={dashboardDetails?.locationPoCount}
                      field="poCount"
                      categoryField="propertyName"
                      key="propertyName"
                      colorField="color"
                      noteTextField="propertyName"
                      notes={false}
                    >
                      <ChartSeriesLabels
                        visible={true}
                        padding={0}
                        color="balck"
                        font="bold 14px Poppins"
                      />
                    </ChartSeriesItem>
                  </ChartSeries>
                </Chart>
              ) : (
                <div style={{ height: "100%" }}>
                  <GraphLoader />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
