import React, { useEffect, useState } from "react";
import CargoForm from "./CargoForm";
import CargoDocuments from "./CargoDocuments";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { formatDate } from "@telerik/kendo-intl";
import KendoLoadingPanel from "../shipmentDetails/KendoLoadingPanel";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../constants";

const CargoDetails = ({ poId }) => {
  const id = poId;
  const [cargo, setCargo] = useState();
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    let res = await axios.get(`${BASE_URL}/cargo/${id}`);
    if (res.status === 200) {
      setCargo(res.data);
    }
  };

  useEffect(() => {
    loadData();
  }, []);
  return (
    <div className="po-details-page shipment-component">
      {!cargo && <KendoLoadingPanel />}
      <div className="voyage-background">
        <img
          src="voyage-banner.png"
          className="banner-image"
          loading="lazy"
          alt="voyage-bg"
          height={200}
        />
        <div className="banner-content">
          <div className="shipment-banner-details">
            <div className="detail-1">Cargo Details</div>
            <div>
              <hr className="detail-divider" />
            </div>
            <div className="detail-desc">{cargo?.poNo}</div>
          </div>
          <div className="detail-2">Warehouse : {cargo?.warehouseName}</div>
          <div className="sub-details">
            <div className="sub-details-1">
              <div className="sub-detail-name">Vessel</div>
              <div className="sub-detail-desc">{cargo?.vesselName}</div>
              <hr className="sub-divider divider-1" />
            </div>
            <div className="sub-details-1">
              <div className="sub-detail-name">Received Date</div>
              <div className="sub-detail-desc">
                {cargo?.receivedDate &&
                  formatDate(new Date(cargo?.receivedDate), "dd MMM yyyy")}
              </div>
              <hr className="sub-divider divider-2" />
            </div>
            <div className="sub-details-1">
              <div className="sub-detail-name">Customer</div>
              <div className="sub-detail-desc">{cargo?.customerName} </div>
            </div>
          </div>
        </div>
        <div className="dashboard-details">
          <Tabs>
            <div className="tabs">
              <div className="tabs-header">
                <label className="label-1">Cargo /</label> Cargo Details
              </div>
              <TabList>
                <Tab>Details</Tab>
                <Tab>Documents</Tab>
              </TabList>
            </div>
            <TabPanel>
              <CargoForm cargo={cargo} />
            </TabPanel>
            <TabPanel>
              <CargoDocuments cargo={cargo} />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default CargoDetails;
