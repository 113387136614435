import React from "react";
import SlidingPane from "react-sliding-pane";
import CargoDetails from "./CargoDetails/CargoDetails";

const SlidingPoDetails = ({ open, poId, closeSlider }) => {
  return (
    <SlidingPane
      closeIcon={
        <button
          type="button"
          className="rounded-md text-black hover:text-white focus:outline-none focus:ring-2 focus:ring-white absolute top-0"
        >
          <span className="sr-only">Close panel</span>
          {/* <XIcon className="h-6 w-6" aria-hidden="true" /> */}
        </button>
      }
      hideHeader
      isOpen={open}
      from="right"
      width="70vw"
      className=""
      onRequestClose={closeSlider}
    >
      <div style={{ padding: "0 10px" }}>
        <button
          style={{
            position: "absolute",
            top: "10px",
            left: "5px",
            border: "0",
            backgroundColor: "transparent",
            cursor: "pointer",
          }}
          onClick={closeSlider}
        >
          <img src="closeIcon.svg" style={{ height: "20px" }} />
        </button>

        <CargoDetails poId={poId} />
      </div>
    </SlidingPane>
  );
};

export default SlidingPoDetails;
