import { ComboBox } from "@progress/kendo-react-dropdowns";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import { BASE_URL } from "../../constants";
import AppContext from "../../context/AppContext";
import "../../style/engineRoom.css";

const EngineRoom = () => {
  const authCtx = useContext(AppContext);
  const [loader, setLoader] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const onSave = async () => {
    setDisabled(true);
    const Docs = {
      customerId: authCtx.profile.id,
      documents: selectedFiles,
    };
    const res = await axios.post(
      `${BASE_URL}/CustomerPortal/DocumentUploader`,
      Docs
    );
    if (res.status === 200) {
      toast.success("Document uploaded successfully!");
    } else {
      toast.error("some error occured when document uploading!");
    }
    setDisabled(false);
  };

  const changeHandler = async (event) => {
    setLoader(true);
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const formData = new FormData();
    formData.append("files", event.target.files[0]);
    const res = await axios.post(
      `${BASE_URL}/storage/documents/upload`,
      formData,
      config
    );
    if (res.status === 200) {
      // save cargo
      toast.success("Document uploaded successfully!");
      //   onDocumentUploaded(res.data);
      const procFiles = res.data.map((f) => {
        return { id: f.id, fileName: f.fileName, size: f.size };
      });
      setSelectedFiles([...selectedFiles, ...procFiles]);
    } else {
      toast.error("some error occured when document uploading!");
    }
    setLoader(false);
  };

  const downloadFile = async (file) => {
    try {
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const response = await fetch(
        `${BASE_URL}/Storage/documents/download?id=${file.id}`,
        {
          config,
          method: "GET",
          headers: {
            Authorization: `Bearer ${authCtx.profile.token}`, // Replace with your actual access token
            // You may need to set other headers depending on your API requirements
          },
        }
      );
      if (response.ok) {
        console.log(response);
        const blob = await response.blob();
        // // Create a download link element
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file.fileName; // Set the desired filename
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error fetching blob content:", error);
    }
  };

  const deleteDoc = async (docId) => {
    const res = await axios.post(
      `${BASE_URL}/storage/documents/delete?id=${docId}`
    );
    if (res.status === 200) {
      toast.success("Document deleted successfully!");
      const exDocIndex = selectedFiles.findIndex((x) => x.documentID === docId);
      selectedFiles.splice(exDocIndex, 1);
    } else toast.error("An error occured while deleting the document");
  };

  const loadDocs = async () => {
    const res = await axios.get(
      `${BASE_URL}/CustomerPortal/GetDocumentsByCustomer?customerId=${authCtx.profile.id}`
    );
    setSelectedFiles(res.data);
  };

  useEffect(() => {
    if (authCtx.profile.id !== undefined) loadDocs();
  }, []);

  return (
    <div className="docUpload-details-page">
      <div className="docUpload-background">
        <img
          src="voyage-banner.svg"
          className="docUpload-image"
          alt="voyage-bg"
        />
        <div className="docUpload-content">
          <div className="enginRoom-detail-1">Document Library</div>
          <div className="enginRoom-detail-2">
            <label className="detail-label">Name</label>:&nbsp;&nbsp;
            {authCtx.profile.displayname}
          </div>
          <div>
            <div className="enginRoom-detail-2">
              <label className="detail-label">Email</label>:&nbsp;&nbsp;
              {authCtx.profile.email}
            </div>
            {/* <div className="enginRoom-detail-2">
              <label className="detail-label">Job Title</label>:&nbsp;&nbsp;
              {authCtx.profile.jobTitle}
            </div>
            <div className="enginRoom-detail-2">
              <label className="detail-label">Role</label>:&nbsp;&nbsp;Customer
            </div> */}
          </div>
        </div>
        <div className="docUplaod-details">
          <div className="docUpload-detail-main">
            <p className="header">Documents</p>
            <form className="form">
              <div className="docmain">
                {authCtx.profile.superAdminRole && (
                  <div className="doc-uploader">
                    <input type="file" name="file" onChange={changeHandler} />
                    <img className="" src="upload.svg" alt="Upload" />
                    <h6 className="">Attach Documents</h6>
                    <p className="">Drag Documents here</p>
                  </div>
                )}
                {loader && (
                  <div className="k-loading-mask">
                    <span className="k-loading-text">Loading</span>
                    <div className="k-loading-image" />
                    <div className="k-loading-color" />
                  </div>
                )}
                <div className="doc-Show-Container">
                  <div className="doc-show">
                    <table>
                      <tbody>
                        {selectedFiles.length > 0 ? (
                          selectedFiles.map((file, fileIdx) => (
                            <tr key={file.documentID}>
                              <td className="pdfimg">
                                <img className="" src="pdf.svg" alt="pdf" />
                              </td>
                              <td className="pdfDetails">
                                {file.fileName}
                                <p className="pdfSize">{file.size}KB</p>
                              </td>

                              <td className="download-div">
                                <a
                                  className=""
                                  //href={`${BASE_URL}/storage/documents/download?id=${file.documentID}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  onClick={() => downloadFile(file)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                    />
                                  </svg>
                                </a>

                                {/* <button
                                  type="button"
                                  className=""
                                  onClick={() =>
                                    deleteDoc(file.documentID)
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                    />
                                  </svg>
                                </button> */}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <div className="engineRoom-empty-documents">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              height={20}
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                              />
                            </svg>
                            No Documents Uploaded
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {selectedFiles.length !== 0 &&
                    authCtx.profile.superAdminRole && (
                      <div className="save">
                        <button
                          type="button"
                          className=""
                          disabled={disabled}
                          onClick={onSave}
                        >
                          Save
                        </button>
                      </div>
                    )}
                </div>
              </div>
            </form>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EngineRoom;
