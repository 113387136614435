import React, { useContext } from "react";
import { BASE_URL, documentTypes } from "../../../constants";
import "./style/shipment-documents.css";
import AppContext from "../../../context/AppContext";
import { useState } from "react";

const ShipmentDocuments = ({ shipments }) => {
  const authCtx = useContext(AppContext);
  const [downloading, setDownloading] = useState(false);

  const downloadFile = async (file) => {
    setDownloading(true);
    try {
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const response = await fetch(
        `${BASE_URL}/Storage/documents/download?id=${file.documentID}`,
        {
          config,
          method: "GET",
          headers: {
            Authorization: `Bearer ${authCtx.profile.token}`, // Replace with your actual access token
            // You may need to set other headers depending on your API requirements
          },
        }
      );
      if (response.ok) {
        console.log(response);
        const blob = await response.blob();

        // // Create a download link element
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file.fileName; // Set the desired filename
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error fetching blob content:", error);
    }
    setDownloading(false);
  };
  return (
    <div className="shipment-poes-main shipment-poes-sub">
      <table className="document-table">
        <thead>
          <tr>
            <th></th>
            <th>Document(Name,Size)</th>
            <th>Document Type</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody className="tdocumentbody">
          {shipments &&
          shipments.documents.filter((d) => d.showInPortal).length !== 0 ? (
            shipments.documents.map(
              (file, fileIdx) =>
                (file.showInPortal === true || file.showInPortal === null) && (
                  <tr key={file.documentID} className="document-table-row">
                    <td className="icon-td">
                      <img className="" height="24px" src="pdf.svg" alt="pdf" />
                    </td>
                    <td className="filename-td">
                      {file.fileName}
                      <p>{file.size}KB</p>
                    </td>
                    <td className="documentType">
                      {
                        documentTypes.find((x) => x.id === file.documentTypeId)
                          ?.name
                      }
                    </td>

                    <td className="download-td">
                      <button
                        className="download-link"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => downloadFile(file)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className=""
                          height="24px"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                          />
                        </svg>
                        {downloading && (
                          <svg
                            class="loading-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              class="opacity-25"
                              style={{ opacity: 0.25 }}
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              class="opacity-75"
                              style={{ opacity: 0.75 }}
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        )}
                      </button>
                    </td>
                  </tr>
                )
            )
          ) : (
            <tr>
              <td colSpan={4}>
                <div className="empty-documents">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    height={20}
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                    />
                  </svg>
                  No Documents Uploaded
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ShipmentDocuments;
