import { ComboBox } from "@progress/kendo-react-dropdowns";
import axios from "axios";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../constants";
import AppContext from "../context/AppContext";

import "../style/login.css";

const Login = () => {
  const [passwordShow, setPasswordShow] = useState();
  const navigate = useNavigate;
  const authCtx = useContext(AppContext);
  const [customerList, setCustomerList] = useState([]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [invalidLogin, setInvalidLogin] = useState(false);
  const [loading, setLoading] = useState(false);

  const onUsernameBlur = () => {
    if (username !== "") {
      axios
        .post(
          `${BASE_URL}/CustomerPortal/CheckCustomerRole?userName=${username}`
        )
        .then((res) => {
          setCustomerList(res.data);
        })
        .catch((error) => {});
    }
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setInvalidLogin(false);
    setLoading(true);
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    // send the username and password to the server
    let payload;
    if (customerList.length > 0) {
      payload = {
        username: username,
        password: password,
        selectedCustomer:
          selectedCustomer === ""
            ? customerList[0]?.userName
            : selectedCustomer,
      };
    } else {
      payload = {
        username: username,
        password: password,
        selectedCustomer: "",
      };
    }
    axios
      .post(`${BASE_URL}/CustomerPortal/login`, payload, config)
      .then((res) => {
        setInvalidLogin(false);
        setLoading(false);
        console.log(res.data);
        authCtx.login(res.data);
      })
      .catch((error) => {
        setInvalidLogin(true);
        setLoading(false);
      });
  };
  return (
    <div className="login-main">
      <div className="login-profile">
        <img src="login-img.svg" className="login-img" alt="login-img" />
        <div className="desc-div">
          <img src="seaDrops.svg" className="img-normal" />
          <div className="profile-text">
            We help you overcome insurmountable deliveries
          </div>
          <img src="seaDrops.svg" className="imgFlip" />
        </div>
      </div>
      <div className="login-form">
        <img src="voyage-logo.svg" className="voyagelogo" />
        <div className="form-content">
          <div className="form-header">
            Hello,
            <br /> Welcome Back
          </div>
          <div className="login-form-elements">
            <input
              type="text"
              id="fname"
              name="fname"
              placeholder="Username"
              value={username}
              onChange={({ target }) => setUsername(target.value)}
              onBlurCapture={onUsernameBlur}
            />
            <div className="password-box">
              <input
                type={passwordShow ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={({ target }) => setPassword(target.value)}
              />
              <button
                type="button"
                className="show-hide-button"
                onClick={() => setPasswordShow(!passwordShow)}
              >
                {passwordShow && <hr className="password-marker" />}
                <img src="hide-password-icon.svg" className="map-hide-logo" />
              </button>
            </div>
            {customerList.length > 0 && username !== "" && (
              <select
                name="customer"
                value={selectedCustomer}
                onChange={(e) => setSelectedCustomer(e.target.value)}
              >
                {customerList.map((c) => {
                  return (
                    <option
                      key={c.id}
                      value={c.userName}
                      label={c.displayname}
                    />
                  );
                })}
              </select>
            )}
          </div>
          <div className="login-forgot">Forgot your password?</div>
          <button type="button" onClick={handleSubmit} className="login-button">
            {loading && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                enable-background="new 0 0 24 24"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#FFFFFF"
                className="animate-spin"
              >
                <g>
                  <path d="M0,0h24v24H0V0z" fill="none" />
                </g>
                <g>
                  <g>
                    <path d="M12,5V2L8,6l4,4V7c3.31,0,6,2.69,6,6c0,2.97-2.17,5.43-5,5.91v2.02c3.95-0.49,7-3.85,7-7.93C20,8.58,16.42,5,12,5z" />
                    <path d="M6,13c0-1.65,0.67-3.15,1.76-4.24L6.34,7.34C4.9,8.79,4,10.79,4,13c0,4.08,3.05,7.44,7,7.93v-2.02 C8.17,18.43,6,15.97,6,13z" />
                  </g>
                </g>
              </svg>
            )}
            Login
          </button>
          {invalidLogin && (
            <p className="login-error" id="email-error">
              Invalid username or password !
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
