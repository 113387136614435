import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { formatDate } from "@telerik/kendo-intl";
import React, { useContext, useEffect, useState } from "react";
import { BASE_URL, movementStatuses } from "../../constants";
import axios from "axios";
import "./../../style/stockReport.css";
import { ComboBox, MultiSelect } from "@progress/kendo-react-dropdowns";
import AppContext from "../../context/AppContext";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import KendoLoadingPanel from "./shipmentDetails/KendoLoadingPanel";
import { useParams } from "react-router-dom";

const StockReport = () => {
  const KendoDateCell = (props) => {
    const value = props.dataItem[props.field];
    return <td>{formatDate(new Date(value), "dd-MMM-yyyy")}</td>;
  };
  const columns = [
    {
      field: "vesselName",
      title: "Vessel",
      width: 200,
    },
    {
      field: "poNo",
      title: "Po No",
      filterable: false,
      width: 200,
    },
    {
      field: "supplierName",
      title: "Supplier",
      filterable: false,
      width: 200,
    },
    {
      field: "colli",
      title: "Qty",
      filterable: false,
      width: 100,
    },
    {
      field: "weight",
      title: "Weight(KG)",
      filterable: false,
      width: 140,
    },
    {
      field: "length",
      title: "Length(CM)",
      filterable: false,
      width: 140,
    },
    {
      field: "width",
      title: "Width(CM)",
      filterable: false,
      width: 140,
    },
    {
      field: "height",
      title: "Height(CM)",
      filterable: false,
      width: 140,
    },
    {
      field: "chargableWeight",
      title: "Ch. Wt.(KG)",
      filterable: false,
      width: 170,
    },
    {
      field: "volume",
      title: "Volume(M3)",
      filterable: false,
      width: 140,
    },
    {
      field: "cargoType",
      title: "Cargo Type",
      filterable: false,
      width: 140,
    },
    {
      field: "currentWarehouse",
      title: "Warehouse",
      filterable: false,
      width: 170,
    },
    {
      field: "location",
      title: "Location",
      filterable: false,
      width: 150,
    },
    {
      field: "movementStatus",
      title: "Status",
      filterable: true,
      width: 150,
    },
    {
      field: "receivedDate",
      title: "Date",
      cell: KendoDateCell,
      filterable: false,
      width: 150,
    },
    {
      field: "shipmentRefNo",
      title: "AWB/BL",
      filterable: false,
      width: 150,
    },
    {
      field: "remarks",
      title: "Ref",
      filterable: false,
      width: 200,
    },
    {
      field: "cargoPickupType",
      title: "Shipment Type",
      filterable: false,
      width: 200,
    },
  ];
  const { searchLoc } = useParams();
  const authCtx = useContext(AppContext);
  const [stock, setStock] = useState([]);
  const [pageState, setPageState] = useState({
    sort: [{ field: "creationDate", dir: "desc" }],
    skip: 0,
    take: 12,
  });
  const [vessel, setVessel] = useState(null);
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState([
    movementStatuses.find((x) => x.id === 200),
  ]);
  const [vessels, setVessels] = useState([]);
  let _exporter;

  const onExport = () => {
    save(_exporter);
  };

  const save = (component) => {
    const options = component.workbookOptions();
    const rows = options.sheets[0].rows;
    const dateColumn = rows[0].cells.find((x) => x.value === "Date");
    const dateIndex = rows[0].cells.indexOf(dateColumn);
    rows.forEach((row) => {
      if (row.type === "data") {
        row.cells[dateIndex].value = formatDate(
          new Date(row.cells[dateIndex].value),
          "dd-MMM-yyyy"
        );
      }
    });
    _exporter.save(options);
  };

  const onPageChange = (e) => {
    console.log(e);
    const state = { ...pageState, skip: e.page.skip, take: e.page.take };
    setPageState(state);
    //loadData(state);
  };

  const loadData = async (loc) => {
    setLoading(true);
    const vesselId = vessel == null ? "~ALL" : vessel.name;
    const searchLocation = loc == "" ? "~ALL" : loc;
    const statusId =
      status == null || status[0].id === 500
        ? "~ALL"
        : status.map((s) => s.name).join(",");
    const customerId = authCtx.profile.customers[0]?.customerId;
    const res = await axios.get(
      `${BASE_URL}/CustomerPortal/stock?vessel=${vesselId}&customerId=${
        customerId !== null && customerId !== undefined ? customerId : 0
      }&status=${statusId}&location=${searchLocation}`
    );

    setStock(res.data.data);
    setLoading(false);
  };

  const loadLookups = async () => {
    let res = await axios.get(`${BASE_URL}/Lookup/VesselByCustomer`);
    setVessels(res.data);
  };

  const search = async () => {
    await loadData(location);
  };

  const onVesselChange = (e) => {
    setVessel(e.value);
  };

  const onStatusChange = (e) => {
    if (e.value[e.value.length - 1].id === 500)
      setStatus(e.value.filter((v) => v.id === 500));
    else setStatus(e.value.filter((v) => v.id !== 500));
  };

  const reset = () => {
    setStock([]);
    setVessel(null);
    setStatus(null);
  };

  useEffect(() => {
    let loc = location;
    if (searchLoc !== "all") {
      loc = searchLoc;
      setLocation(searchLoc);
    }
    loadData(loc);
    loadLookups();
  }, []);

  return (
    <div className="shipment-main">
      {/* {mapShow && (
        <div className="map-container">
          <img src="map.svg" className="map" alt="map" />
          <div className="pointer1">
            <img src="shipPointer.svg" alt="pointer" />
            <img src="pointer.svg" alt="pointer" />
          </div>
          <div className="pointer2">
            <img src="shipPointer.svg" alt="pointer" />
            <img src="pointer.svg" alt="pointer" />
          </div>
          <div className="pointer3">
            <img src="shipPointer.svg" alt="pointer" />
            <img src="pointer.svg" alt="pointer" />
          </div>
          <div className="pointer4">
            <img src="shipPointer.svg" alt="pointer" />
            <img src="pointer.svg" alt="pointer" />
          </div>
          <div className="pointer5">
            <img src="shipPointer.svg" alt="pointer" />
            <img src="pointer.svg" alt="pointer" />
          </div>
        </div>
      )} */}
      <div className="list-container">
        <div className="header">
          <div className="header-details">
            <div className="header-name">
              <div className="header-name1">Stock Report</div>
              {/* <div className="header-name2">Some Label</div> */}
            </div>
            <div className="list-count">{stock.length}</div>
          </div>
          <div className="stock-header-actions">
            <div id="compo">
              <ComboBox
                data={vessels}
                name="vesselId"
                textField="name"
                style={{ height: "30px" }}
                placeholder="Enter vessel"
                onChange={onVesselChange}
                value={vessel}
                suggest={true}
                clearButton={false}
              />
            </div>
            <div>
              <input
                name="location"
                placeholder="Location"
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
                value={location}
                className="location-input"
              />
            </div>
            <div className="stockMultiSelect" id="compo">
              <MultiSelect
                data={movementStatuses.filter((m) => m.id !== 100)}
                name="statusId"
                textField="name"
                style={{ height: "30px" }}
                placeholder="Enter status"
                onChange={onStatusChange}
                value={status}
                suggest={true}
                clearButton={false}
              />
            </div>
            <button
              className="stock-button" // disabled={
              onClick={search}
            >
              Search
            </button>
            <button
              className="stock-button" // disabled={
              onClick={reset}
            >
              Reset
            </button>
            <button
              className="stock-export-button" // disabled={
              onClick={onExport}
            >
              <img src="export.svg" alt="export-icon" />
              Export
            </button>
          </div>
        </div>
        <div className="grid">
          {loading && <KendoLoadingPanel />}
          <ExcelExport
            ref={(exporter) => {
              _exporter = exporter;
            }}
            data={stock}
          >
            <Grid
              data={process(stock, pageState)}
              pageable={true}
              className=""
              style={{
                borderRadius: "10px",
                borderColor: "#E5E7EB",
                fontSize: "12px",
                fontFamily: "poppins",
                zIndex: 0,
                height: "77.5vh",
              }}
              onPageChange={onPageChange}
              {...pageState}
            >
              {columns.map((column) => {
                return (
                  // !column.hidden && (
                  <GridColumn
                    key={column.field}
                    field={column.field}
                    title={column.title}
                    width={column.width}
                    filterable={column.filterable ? true : false}
                    sortable={column.sortable ? true : false}
                    cell={column.cell}
                  />
                );
                // );
              })}
            </Grid>
          </ExcelExport>
        </div>
      </div>
    </div>
  );
};

export default StockReport;
