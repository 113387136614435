import React from "react";
import { TextLoader } from "./TextLoader";

const ListLoader = () => {
  const divArray = Array.from({ length: 13 }, (_, index) => index);
  return (
    <div role="status" class="list-loader-container">
      {divArray.map((i) => (
        <div class="loader-list" key={i}>
          <div class="list-item-1"></div>
          {/* <div class="list-item-2"></div> */}
          <div style={{ width: "28px" }}>
            <TextLoader />
          </div>
          {/* <div class="list-item-2"></div> */}
        </div>
      ))}
      <span class="sr-only">Loading...</span>
    </div>
  );
};

export default ListLoader;
