import { formatDate } from "@telerik/kendo-intl";
import React from "react";
import { CargoPickupTypes, CargoType } from "../../../constants";
import "./style/CargoForm.css";

const CargoForm = ({ cargo }) => {
  console.log(cargo);
  
  return (
    <div
      className="shipment-detail-main"
      style={{ overflowY: "scroll", height: "100%" }}
    >
      <div className="purchase-order-detail">
        <div className="grid-container">
          <div className="grid-item">
            <h2>Supplier:</h2>
            <p>{cargo?.supplierName}</p>
            {/* <p>{cargo?.supplierAddress}</p>
            <p>{cargo?.supplierEmail}</p>
            <p>{cargo?.supplierPhone}</p> */}
          </div>
          <div className="grid-item">
            <h2>Cargo Type:</h2>
            <p>
              {cargo?.cargoPickupType &&
                CargoPickupTypes.find((cp) => cp.id === cargo.cargoPickupType)
                  .name}
            </p>
          </div>
          <div className="grid-item">
            <h2>Total Value:</h2>
            <p>{cargo?.totalValue}</p>
          </div>
          <div className="grid-item">
            <h2>Classification:</h2>
            <p>
              {cargo?.cargoType &&
                CargoType.find((cp) => cp.id === cargo.cargoType).name}
            </p>
          </div>
          <div className="grid-item">
            <h2>Commodity:</h2>
            <p>{cargo?.commodity}</p>
          </div>
          <div className="grid-item">
            <h2>Country of Origin:</h2>
            <p>{cargo?.cooName}</p>
          </div>
          <div className="grid-item">
            <h2>Agent:</h2>
            <p>{cargo?.agentName}</p>
          </div>
          <div className="grid-item">
            <h2>Remarks:</h2>
            <p>{cargo?.remarks}</p>
          </div>
        </div>
      </div>
      <div className="package-div">
        <div className="div-header relative">
          PACKAGES
          <div className="container-count">
            {
              cargo?.packages.length
              //.map((poe) => poe.package?.length)
              //.reduce((a, b) => a + b, 0)
            }
          </div>
        </div>
        <div className="container-grid">
          {cargo?.packages.map((c) => {
            return (
              <div
                className="container"
                key={c.id}
                style={{
                  width: cargo.packages.length === 1 ? "70%" : "80%",
                }}
              >
                <div>
                  <img src="container-icon.svg" />
                </div>
                <div>
                  <div className="dimension">
                    {c.height} x {c.width} x {c.length}‘ HC
                  </div>
                  <div className="job-details">
                    <div className="job-type">
                      <span className="label">Job Type</span>:&nbsp;&nbsp;
                      <span className="value">
                        {c.jobType && c.jobType === 100
                          ? "WH-WH"
                          : c.jobType === 200
                          ? "WH-V"
                          : c.jobType === 300
                          ? "WH-D"
                          : "O-WH"}
                      </span>
                    </div>
                    <div className="job-no">
                      <span className="label">Job No</span>:&nbsp;&nbsp;
                      <span className="value">{c.jobNo}</span>
                    </div>
                    <div className="job-no">
                      <span className="label">Total pcs</span> :&nbsp;&nbsp;
                      <span className="value"> {c.colli}</span>
                    </div>
                    <div className="job-no">
                      <span className="label">Location</span> :&nbsp;&nbsp;
                      <span className="value"> {c.location}</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CargoForm;
