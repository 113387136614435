import logo from "./logo.svg";
import "./App.css";
import Layout from "./components/Layout";
import { HashRouter, Route, Routes } from "react-router-dom";
import ShipmentDetails from "./components/masters/shipmentDetails/ShipmentDetails";
import Shipments from "./components/masters/Shipments";
import Pos from "./components/masters/Pos";
import { useContext, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./components/masters/Dashboard";
import Login from "./components/Login";
import AppContext from "./context/AppContext";
import EngineRoom from "./components/masters/EngineRoom";
import StockReport from "./components/masters/StockReport";
import CargoDetails from "./components/masters/CargoDetails/CargoDetails";

function App() {
  const authCtx = useContext(AppContext);
  const [mapShow, setMapShow] = useState(false);
  const isLoggedIn = authCtx.isLoggedIn;
  console.log(isLoggedIn);
  return (
    <div className="App">
      <HashRouter>
        {isLoggedIn ? (
          <Layout setMapShow={setMapShow} mapShow={mapShow}>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="shipmentDetails/:id" element={<ShipmentDetails />} />
              <Route path="cargoDetails/:id" element={<CargoDetails />} />
              <Route
                path="shipments/:filterby/:id"
                element={<Shipments mapShow={mapShow} />}
              />
              <Route path="pos/:filterby/:id" element={<Pos />} />
              <Route path="engineRoom" element={<EngineRoom />} />
              <Route path="stock/:searchLoc" element={<StockReport />} />
            </Routes>
          </Layout>
        ) : (
          <Login />
        )}
      </HashRouter>
    </div>
  );
}

export default App;
