import React from "react";
import "./style/shipment-poes.css";

const ShipmentPoes = ({ shipment }) => {
  return (
    <div className="shipment-poes-main">
      <div class="shipment-poes-sub">
        <table style={{maxHeight:"10vh"}}>
          <thead>
            <tr>
              <th scope="col">PO Number</th>
              <th scope="col">Vessel</th>
              <th scope="col">Supplier</th>
              <th scope="col">boxes</th>
              <th scope="col">weight</th>
              <th scope="col">LxWxH</th>
            </tr>
          </thead>
          <tbody>
            {shipment?.poes &&
              shipment?.poes.map((v) => {
                return (
                  <tr class="bg-white border-b">
                    <td
                      scope="row"
                      class="poNo py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                    >
                      {v.poNo}
                    </td>
                    <td class="py-4 px-6 border-l">{v.vesselName}</td>
                    <td class="py-4 px-6 border-l">{v.supplierName}</td>
                    <td class="py-4 px-6 border-l">{v.package.colli}</td>
                    <td class="py-4 px-6 border-l">{v.package.weight} KG</td>
                    <td class="py-4 px-6 border-l">
                      {v.package.height}X{v.package.width}X{v.package.length}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ShipmentPoes;
