export const BASE_URL = "https://vml-api.azurewebsites.net"
// export const BASE_URL = "https://vml-api-test.azurewebsites.net";
//export const BASE_URL = "https://localhost:44382";
// export const BASE_URL = "https://localhost:7251";

export const movementStatuses = [
  { id: 100, name: "NA" },
  { id: 200, name: "InWarehouse" },
  { id: 300, name: "InTransit" },
  { id: 400, name: "Delivered" },
  { id: 500, name: "Shipped" },
];
export const CargoType = [
  { name: "General", id: 100 },
  { name: "Hazardous ", id: 200 },
];
export const TransportModes = [
  { id: 100, name: "Air" },
  { id: 200, name: "Sea" },
  { id: 300, name: "Road" },
  { id: 400, name: "Courier" },
  { id: 500, name: "Others" },
];
export const documentTypes = [
  { id: 1, name: "Commercial Invoice" },
  { id: 2, name: "Packing List" },
  { id: 3, name: "POD" },
  { id: 4, name: "Airway Bill" },
  { id: 5, name: "Bill Of Lading" },
  { id: 6, name: "Warehouse Note" },
  { id: 7, name: "VML Billing Invoice" },
  { id: 8, name: "Photos" },
  { id: 10, name: "Pre Alert" },
  { id: 9, name: "Others" },
];
export const CargoPickupTypes = [
  { id: 100, name: "Local" },
  { id: 200, name: "Pickup" },
];