import React, { useState, useEffect, useRef } from "react";
import "../../style/poes.css";
import { BsTriangle } from "react-icons/bs";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL, movementStatuses } from "../../constants";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { formatDate } from "@telerik/kendo-intl";
import { getter, process } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { ComboBox, MultiSelect } from "@progress/kendo-react-dropdowns";
import KendoLoadingPanel from "./shipmentDetails/KendoLoadingPanel";
import { DateRangePicker } from "@progress/kendo-react-dateinputs";
import SlidingPoDetails from "./SlidingPoDetails";
import "react-sliding-pane/dist/react-sliding-pane.css";

const KendoDateCell = (props) => {
  const value = props.dataItem[props.field];
  return (
    <td className=" bg-inherit">
      {formatDate(new Date(value), "dd-MMM-yyyy")}
    </td>
  );
};

const DetailComponent = (props) => {
  const po = props.dataItem;
  return (
    <section>
      <table className="po-detail-table">
        <thead>
          <tr>
            <th scope="col">LxWxH</th>
            <th scope="col">Qty</th>
            <th scope="col">Weight</th>
            <th scope="col">Volume</th>
            <th scope="col">Ch. Weight</th>
            <th scope="col">Active Job No</th>
            <th scope="col">Destination Port</th>
            <th scope="col">Location</th>
            <th scope="col">Warehouse</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {po.packages.map((pkg) => (
            <tr key={pkg.id}>
              <td>
                {pkg.length}x{pkg.width}x{pkg.height} cm
              </td>
              <td>{pkg.colli}</td>
              <td>{pkg.weight} kg</td>
              <td>{pkg.volume.toFixed(2)} m3</td>
              <td>{pkg.chargableWeight.toFixed(2)} kg</td>
              <td>{pkg.jobNo}</td>
              <td>{pkg.destinationPort}</td>
              <td>{pkg.location}</td>
              <td>{pkg.currentWarehouseName}</td>
              <td>
                {
                  movementStatuses.find((x) => x.id === pkg.movementStatus)
                    ?.name
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

const itemRender = (li, itemProps) => {
  const index = itemProps.dataItem.count;
  const itemChildren = (
    <div
      style={{
        color: "black",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <span style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <img
          src={itemProps.dataItem.icon}
          alt={itemProps.dataItem.name}
          width={15}
        />
        {li.props.children}
      </span>
      <span
        style={{
          backgroundColor: "rgb(209 213 219)",
          borderRadius: "9999px",
          height: "25px",
          width: "25px",
          display: "flex",
          color: "black",
          fontSize: "12px",
          fontWeight: "bolder",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {index}
      </span>
    </div>
  );
  return React.cloneElement(li, li.props, itemChildren);
};

const Pos = () => {
  const { filterby, id } = useParams();
  const columns = [
    {
      field: "vesselName",
      width: "250",
      textAlign: "center",
      headerText: "Vessel",
    },
    {
      headerText: "PO Number",
      width: "250",
      textAlign: "center",
      field: "poNo",
    },
    {
      headerText: "Date",
      field: "creationDate",
      width: "248",
      textAlign: "center",
      format: "dd/MM/yyyy",
      cell: KendoDateCell,
    },
    {
      headerText: "Total Weight(KG)",
      field: "totalWeight",
      width: "auto",
      textAlign: "center",
    },
    {
      headerText: "Supplier",
      field: "supplierName",
      width: "250",
      textAlign: "center",
    },
    {
      headerText: "Origin Warehouse",
      field: "warehouseName",
      width: "248",
      textAlign: "center",
    },
    {
      headerText: "Status",
      field: "status",
      width: "200",
      textAlign: "center",
    },
   
  ];

  const defaultStatus = [
    {
      name: "ALL",
      value: "all",
      icon: "allPoIcon.svg",
      count: 0,
    },
    {
      name: "In Transit",
      value: "InTransit",
      icon: "transitIcon.svg",
      count: 0,
    },
    {
      name: "In Stock",
      value: "InWarehouse",
      icon: "stockIcon.svg",
      count: 0,
    },
    {
      name: "Delivered",
      value: "Delivered",
      icon: "deliveredIcon.svg",
      count: 0,
    },
    {
      name: "Hanging",
      value: "NA",
      icon: "hangingIcon.svg",
      count: 0,
    },
  ];
  const defaultPageState = {
    sort: [{ field: "creationDate", dir: "desc" }],
    skip: 0,
    take: 11,
  };
  const _export = useRef();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [filteredPoes, setfilteredPoes] = useState([]);
  const [poes, setPoes] = useState([]);
  const [vessel, setVessel] = useState(null);
  const [status, setStatus] = useState(null);
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const [vessels, setVessels] = useState([]);
  const [poCount, setPoCount] = useState([]);
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [pageState, setPageState] = useState(defaultPageState);
  const [openSlider, setSliderOpen] = useState(false);
  const [selectedPoId, setSelectedPoId] = useState();

  const loadLookups = async () => {
    let res = await axios.get(`${BASE_URL}/Lookup/VesselByCustomer`);
    setVessels(res.data);
  };
  console.log(poes)
  const LoadData = async (range) => {
    setLoading(true);
    setPageState(defaultPageState);
    const startDate = range.start.toISOString().slice(0, 23) + "Z";
    const endDate = range.end.toISOString().slice(0, 23) + "Z";
    const filterOptions =
      filterby === "vessel"
        ? `filterStrValue=all&filterIntValue=${id}`
        : filterby === "all"
          ? "filterStrValue=all"
          : `filterStrValue=${id}`;
    const res = await axios.get(
      `${BASE_URL}/CustomerPortal/Cargo?filterType=${filterby}&${filterOptions}&startRange=${startDate}&endRange=${endDate}`
    );
    let finalres = res.data;

    if (res.status === 200) {
      
      setPoes(
        finalres.map((d) => ({
          ...d,
          totalWeight: d.packages.reduce((a, b) => a + b.weight, 0),
          status: movementStatuses.find(
            (m) => m.id == d.packages[0]?.movementStatus
          )?.name,
        }))
      );
      
      setfilteredPoes(
        finalres.map((d) => ({
          ...d,
          totalWeight: d.packages.reduce((a, b) => a + b.weight, 0),
          status: movementStatuses.find(
            (m) => m.id == d.packages[0]?.movementStatus
          )?.name,
        }))
      );
      setStatus(
        filterby === "status"
          ? defaultStatus.find((ds) => ds.value === id)
          : {
            name: "ALL",
            value: "all",
            icon: "allPoIcon.svg",
            count: finalres.length,
          }
      );
      filterby !== "status" &&
        setPoCount(
          defaultStatus.map((ds) => ({
            ...ds,
            count:
              ds.value === "all"
                ? finalres.length
                : finalres.filter(
                  (item) =>
                    item.packages[0]?.movementStatus ===
                    movementStatuses.find((ms) => ms.name === ds.value).id
                ).length,
          }))
        );
    }
    setLoading(false);
  };

  const excelExport = async () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const onSearch = () => {
    let data = poes;
    setfilteredPoes([]);
    setLoading(true);
    if (searchText !== "") {
      data = data.filter((p) =>
        p.poNo.toUpperCase().startsWith(searchText.toUpperCase())
      );
    }
    if (vessel !== null) {
      data = data.filter((d) => vessel.map((x) => x.id).includes(d.vesselId));
    }
    if (location !== "") {
      data = data.filter((d) => {
        return d.packages.some((p) => {
          return p.location.toUpperCase().startsWith(location.toUpperCase());
        });
      });
    }
    if (status !== null && status.value !== "all") {
      data = data.filter(
        (item) =>
          item.packages[0]?.movementStatus ===
          movementStatuses.find((ms) => ms.name === status.value)?.id
      );
    }
    setLoading(false);
    setfilteredPoes(data);
  };

  const onReset = () => {
    setfilteredPoes(poes);
    setSearchText("");
    setLocation("");
    setVessel(null);
  };

  const onVesselChange = (e) => {
    setVessel(e.value);
    console.log(e.value);
  };

  const handleDateChange = (e) => {
    setDateRange(e.value);
  };

  const expandChange = (event) => {
    let newData = filteredPoes.map((item) => {
      if (item.id === event.dataItem.id) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setfilteredPoes(newData);
  };

  const onPageChange = (e) => {
    const state = { ...pageState, skip: e.page.skip, take: e.page.take };
    setPageState(state);
    //loadData(state);
  };

  const onRowDoubleClick = (e) => {
    setSelectedPoId(e.dataItem.id);
    setSliderOpen(true);
    // navigate(`/cargoDetails/${e.dataItem.id}`);
  };

  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const startDate = new Date(currentYear, 0, 1);
    const range = { start: startDate, end: currentDate };
    setDateRange(range);
    LoadData(range);
    loadLookups();
  }, []);

  return (
    <div className="main">
      <div className="list-container">
        <div className="header">
          <div className="header-details">
            <div className="header-name">
              <div className="header-name1">Purchase Order</div>
              {/* <div className="header-name2">Some Label</div> */}
            </div>
            <div className="list-count">{filteredPoes.length}</div>
          </div>
          <div>
            <div className="po-header-actions">
              <div id="compo">
                <ComboBox
                  data={poCount}
                  name="poCount"
                  textField="name"
                  style={{ height: "34px" }}
                  placeholder="Status"
                  itemRender={itemRender}
                  onChange={(e) => {
                    setStatus(e.value);
                  }}
                  value={status}
                  suggest={true}
                  clearButton={false}
                  disabled={filterby === "status"}
                />
              </div>
              <div id="compo">
                <MultiSelect
                  data={vessels}
                  name="vesselId"
                  textField="name"
                  style={{ width: "300px", height: "34px" }}
                  className="MultCompo"
                  placeholder="Enter vessel"
                  onChange={onVesselChange}
                  value={vessel}
                  suggest={true}
                  clearButton={false}
                />
              </div>
              <div>
                <input
                  name="location"
                  placeholder="Location"
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                  value={location}
                  className="po-location-input"
                />
              </div>
              <div className="search-div">
                <input
                  name="statusId"
                  textField="name"
                  placeholder="Enter PO Number"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  className="search-input"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="search-icon"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </div>
              <div className="po-action-btn-container">
                <button
                  className="po-action-button" // disabled={
                  onClick={onSearch}
                  title="Search"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    style={{ height: "18px" }}
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </button>
                <button
                  className="po-action-button" // disabled={
                  onClick={onReset}
                  title="Reset"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#FFFFFF"
                  >
                    <g>
                      <path d="M0,0h24v24H0V0z" fill="none" />
                    </g>
                    <g>
                      <g>
                        <path d="M12,5V2L8,6l4,4V7c3.31,0,6,2.69,6,6c0,2.97-2.17,5.43-5,5.91v2.02c3.95-0.49,7-3.85,7-7.93C20,8.58,16.42,5,12,5z" />
                        <path d="M6,13c0-1.65,0.67-3.15,1.76-4.24L6.34,7.34C4.9,8.79,4,10.79,4,13c0,4.08,3.05,7.44,7,7.93v-2.02 C8.17,18.43,6,15.97,6,13z" />
                      </g>
                    </g>
                  </svg>
                </button>
                <button
                  className="excelExport" // disabled={
                  onClick={excelExport}
                  title="Export To Excel"
                >
                  <img src="export.svg" alt="export-icon" />
                  Excel
                </button>
              </div>
            </div>
            <div className="po-dashboard-actions">
              <div className="report-date">
                <label className="report-label">Report of </label>
              </div>
              <div className="action-buttons">
                <div className="date-range-picker">
                  <DateRangePicker
                    style={{
                      height: "100%",
                      zIndex: "0",
                      position: "relative",
                    }}
                    value={dateRange}
                    onChange={handleDateChange}
                  />
                </div>
                <button
                  type="button"
                  className="action-filter"
                  onClick={() => LoadData(dateRange)}
                  disabled={dateRange.end === null || dateRange.end === null}
                >
                  <img src="filterIcon.svg" />
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="po-grid">
          {loading && <KendoLoadingPanel />}
          <ExcelExport data={filteredPoes} ref={_export}>
            <Grid
              data={process(filteredPoes, pageState)}
              pageable={true}
              theme="bootstrap"
              className=""
              style={{
                borderRadius: "10px",
                borderColor: "#E5E7EB",
                fontSize: "12px",
                fontFamily: "poppins",
                zIndex: 0,
                marginTop: "17px",
                height: "76.5vh",
              }}
              onPageChange={onPageChange}
              detail={DetailComponent}
              expandField="expanded"
              onExpandChange={expandChange}
              onRowDoubleClick={onRowDoubleClick}
              {...pageState}
            >
              {columns.map((column) => {

                return (
                  // !column.hidden && (
                  <GridColumn
                    key={column.field}
                    field={column.field}
                    title={column.headerText}
                    // width={column.width}
                    filterable={column.filterable ? true : false}
                    sortable={column.sortable ? true : false}
                    cell={column.cell}
                  />
                );
                // );
              })}
            </Grid>
          </ExcelExport>
        </div>
        <SlidingPoDetails
          open={openSlider}
          poId={selectedPoId}
          closeSlider={() => setSliderOpen(false)}
        />
      </div>
    </div>
  );
};

export default Pos;
