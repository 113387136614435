import { formatDate } from "@telerik/kendo-intl";
import React from "react";
import "./style/shipment-form.css";
import { TransportModes } from "../../../constants";

const ShipmentForm = ({ shipment }) => {
  const transportationStatus = [
    {
      id: 1,
      status: "ETD",
      place: "United States, HOU",
      time: "Dec 4, 2:25 PM",
    },
    {
      id: 2,
      status: "Arrive ar load port",
      place: "United States, HOU",
      time: "Dec 4, 2:25 PM",
    },
    {
      id: 3,
      status: "Pre alert sent",
      place: "United States, HOU",
      time: "Dec 4, 2:25 PM",
    },
    {
      id: 4,
      status: "Booking received",
      place: "United States, HOU",
      time: "Dec 4, 2:25 PM",
    },
  ];
  const clearence = [
    {
      id: 1,
      status: "ETD",
      place: "United States, HOU",
      time: "Dec 4, 2:25 PM",
    },
    {
      id: 2,
      status: "Arrive ar load port",
      place: "United States, HOU",
      time: "Dec 4, 2:25 PM",
    },
  ];
  const warehouse = [
    {
      id: 1,
      status: "ETD",
      place: "United States, HOU",
      time: "Dec 4, 2:25 PM",
    },
    {
      id: 2,
      status: "Arrive ar load port",
      place: "United States, HOU",
      time: "Dec 4, 2:25 PM",
    },
  ];
  const container = [
    {
      id: 1,
      height: 8,
      width: 40,
      containerCount: 6,
    },
    {
      id: 1,
      height: 5,
      width: 40,
      containerCount: 4,
    },
    {
      id: 1,
      height: 2,
      width: 40,
      containerCount: 10,
    },
    {
      id: 1,
      height: 8,
      width: 40,
      containerCount: 6,
    },
    {
      id: 1,
      height: 8,
      width: 40,
      containerCount: 6,
    },
    {
      id: 1,
      height: 8,
      width: 40,
      containerCount: 6,
    },
  ];
  return (
    <div
      className="shipment-detail-main"
      style={{ overflowY: "scroll", height: "100%" }}
    >
      <div className="top-div">
        <div className="shipment-details">
          <div className="div-header">SHIPMENT DETAILS</div>
          <div className="details-main">
            <div className="details-details">
              <div className="shipment-label">ETD</div>
              <div className="label-property">
                {shipment?.etd &&
                  formatDate(new Date(shipment?.etd), "dd-MMM-yyyy")}
              </div>
            </div>
          </div>
          <div className="details-main">
            <div className="details-details">
              <div className="shipment-label">ETA</div>
              <div className="label-property">
                {shipment?.eta &&
                  formatDate(new Date(shipment?.eta), "dd-MMM-yyyy")}
              </div>
            </div>
          </div>
          <div className="details-main">
            <div className="details-details">
              <div className="shipment-label">
                {shipment?.mot === 100
                  ? "AWB No"
                  : shipment?.mot === 200
                  ? "B/L No"
                  : shipment?.mot === 300
                  ? "Customs Doc No"
                  : shipment?.mot === 400
                  ? "AWB NO"
                  : shipment?.mot === 500 && "Reference Number"}
              </div>
              <div className="label-property">{shipment?.awb}</div>
            </div>
          </div>
          <div className="details-main">
            <div className="details-details">
              <div className="shipment-label">
                {shipment?.mot === 100
                  ? "AirLine"
                  : shipment?.mot === 200
                  ? "Carrier/Shipping Line"
                  : shipment?.mot === 300
                  ? "Truck No"
                  : shipment?.mot === 400
                  ? "Carrier"
                  : shipment?.mot === 500 && "Remarks"}
              </div>
              <div className="label-property">
                {shipment?.mot === 100
                  ? shipment?.airLine
                  : shipment?.mot === 200
                  ? shipment?.shippingLine
                  : shipment?.mot === 300
                  ? shipment?.truckNo
                  : shipment?.mot === 400
                  ? shipment?.carrier
                  : shipment?.mot === 500 && shipment?.remarks}
                {shipment?.truckNo}
              </div>
            </div>
          </div>
          {(shipment?.mot === 100 || shipment?.mot === 200) && (
            <div className="details-main">
              <div className="details-details">
                <div className="shipment-label">
                  {shipment?.mot === 100
                    ? "Flt Details"
                    : shipment.mot === 200 && "Vessel's name"}
                </div>
                <div className="label-property">
                  {shipment.mot === 100
                    ? shipment?.fltDetails
                    : shipment?.mot === 200 && shipment?.seaVessel}
                  {shipment?.truckNo}
                </div>
              </div>
            </div>
          )}
          {shipment?.mot === 200 && (
            <div className="details-main">
              <div className="details-details">
                <div className="shipment-label">Voyage</div>
                <div className="label-property">{shipment?.voyage}</div>
              </div>
            </div>
          )}
          {/* <div className="details-main">
            <div className="details-details">
              <div className="shipment-label">Some Label</div>
              <div className="label-property">Some Text</div>
            </div>
          </div>
          <div className="details-main">
            <div className="details-details">
              <div className="shipment-label">Some Label</div>
              <div className="label-property">Some Text</div>
            </div>
          </div> */}
        </div>
        <div className="events">
          <div className="div-header">EVENTS</div>
          <div className="div-content">
            <ol class="timeline-line">
              <div className="timeline-box ">
                <li className="timeline-header timeline">
                  <div className="timeline-container w-full">
                    <img src="seaIcon.svg" className="timeline-Icon" />
                    <p className="sea-header">
                      {shipment?.mot &&
                        TransportModes.find(
                          (x) => x.id === shipment.mot
                        ).name.toUpperCase()}
                      &nbsp;TRANSPORTATION
                    </p>
                  </div>
                </li>
                {shipment?.events &&
                  shipment.events.map((t) => (
                    <li className="timeline">
                      <div className="timeline-container w-full">
                        <img
                          src="timeline-tick.svg"
                          className="timeline-tick"
                        />
                        <div className="timeline-desc">
                          <div className="">
                            <p className="descript-1">{t.eventName}</p>
                            <p className="descript-2 -mt-1">
                              {t.eventDescription}
                            </p>
                          </div>
                          <div className="descript-2">
                            {t.eventDate &&
                              formatDate(new Date(t.eventDate), "dd-MMM-yyyy")}
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
              </div>
            </ol>
          </div>
        </div>
      </div>
      <div className="package-div">
        <div className="div-header relative">
          PACKAGES
          <div className="container-count">
            {
              shipment?.poes.length
              //.map((poe) => poe.package?.length)
              //.reduce((a, b) => a + b, 0)
            }
          </div>
        </div>
        <div className="container-grid">
          {shipment?.poes.map((c) => {
            return (
              <div
                className="container"
                key={c.package.id}
                style={{
                  width: shipment.poes.length === 1 ? "60%" : "80%",
                }}
              >
                <div>
                  <img src="container-icon.svg" />
                </div>
                <div>
                  <div className="dimension">
                    {c.package.height} x {c.package.width} x {c.package.length}‘
                    HC
                  </div>
                  <div className="total-count">
                    Total Container : {c.package.colli}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ShipmentForm;
