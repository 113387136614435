import React, { Children, useContext, useState } from "react";
import "../style/layout.css";
import { Disclosure } from "@headlessui/react";
import { Fragment } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useMatch,
} from "react-router-dom";
import AppContext from "../context/AppContext";
import { useEffect } from "react";

const Layout = ({ children, setMapShow, mapShow }) => {
  const authCtx = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const match = useMatch("/engineRoom");
  const nav = [
    { id: 1, name: "Dashboard", href: "dashboard", current: true },
    { id: 2, name: "Shipments", href: "shipments/all/all", current: false },
    { id: 3, name: "POs", href: "pos/all/all", current: false },
    { id: 4, name: "Stock Report", href: "stock/all", current: false },
  ];
  const [navigation, setNavigation] = useState(nav);
  console.log(location, "location");
  const onLogout = () => {
    authCtx.logout();
    navigate("#");
  };

  useEffect(() => {
    let locationId;
    if (
      location.pathname.startsWith("/dashboard", 0) ||
      location.pathname === "/"
    )
      locationId = 1;
    else if (location.pathname.startsWith("/shipments", 0)) locationId = 2;
    else if (location.pathname.startsWith("/pos", 0)) locationId = 3;
    setNavigation(
      navigation.map((n) =>
        n.id === locationId ? { ...n, current: true } : { ...n, current: false }
      )
    );
  }, [location]);

  return (
    <div className="main">
      <div className="navbar">
        <div className="navbar-content1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="bars"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
          <hr className="line" />
          <img src="vml_logo.png" className="logoShip" />
          {/* <div className="logoName">
            <div className="logoText1">VML</div>
            <div className="logoText2">Customer Portal</div>
          </div> */}
          <div className="tablist">
            {navigation.map((item) => (
              <div key={item.name} className="defaultTab">
                <NavLink
                  key={item.name}
                  to={item.href}
                  style={({ isActive }) => ({
                    color: isActive || item.current ? "#fff" : "#000000",
                    background:
                      isActive || item.current ? "#0082fb" : "#ffffff",
                    width: "90px",
                  })}
                  onClick={() =>
                    setNavigation((prev) =>
                      prev.map((n) =>
                        n.name === item.name
                          ? { ...n, current: true }
                          : { ...n, current: false }
                      )
                    )
                  }
                  className="defaultTab"
                >
                  {item.name}
                </NavLink>
              </div>
            ))}
          </div>
        </div>
        <div className="navbar-content2">
          {/* {location.pathname.startsWith("/shipments") && (
            <button
              type="button"
              className="map-hider"
              onClick={() => setMapShow(!mapShow)}
            >
              {mapShow && <hr className="eye-marker" />}
              <img src="hide-map-icon.svg" className="map-hide-logo" />
              {mapShow ? "Hide" : "Show"} Map
            </button>
          )} */}
          <div className="profile">
            {/* {authCtx.profile.superAdminRole && ( */}
            <div className="defaultTab">
              <NavLink
                key="engine"
                to="engineRoom"
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#000000",
                  background: isActive ? "#0082fb" : "#ffffff",
                  width: "120px",
                  display: "flex",
                  gap: "5px",
                })}
                className="defaultTab"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill={match ? "#FFFFFF" : "#000000"}
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H8V4h12v12zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm12 6V9c0-.55-.45-1-1-1h-2v5h2c.55 0 1-.45 1-1zm-2-3h1v3h-1V9zm4 2h1v-1h-1V9h1V8h-2v5h1zm-8 0h1c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1H9v5h1v-2zm0-2h1v1h-1V9z" />
                </svg>
                Engine Room
              </NavLink>
            </div>
            {/* )} */}
            {/* <div className="bell-circle">
              <img src="bell.svg" className="bell-icon" />
              <div className="notify-dot"></div>
            </div> */}
            <div className="profile-dropdown">
              <div className="profile-pic">
                {/* <img src="profile-pic.svg" /> */}
                {authCtx.profile.displayname.toString().substring(0, 1)}
              </div>
              <div className="profile-detail">
                <div className="profile-name">
                  {authCtx.profile.superAdminRole
                    ? authCtx.profile.superAdminRole.displayname
                    : authCtx.profile.displayname}
                </div>
                <div className="profile-role">
                  {authCtx.profile.superAdminRole
                    ? authCtx.profile.displayname
                    : authCtx.profile.jobTitle}
                </div>
              </div>
              <div class="profile-dropdown-content">
                <button type="button" onClick={onLogout}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="red"
                    height="20"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                    />
                  </svg>
                  {/* Logout */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="children">{children}</div>
    </div>
  );
};

export default Layout;
